import {createBrowserRouter} from 'react-router';

import {ErrorBoundary, wrapCreateBrowserRouter} from '@sentry/react';
import {Home} from '../modules/home/pages/Home.tsx';
import {AppShell} from '../modules/common/components/appShell/AppShell.tsx';
import {ErrorBoundaryFallback} from './ErrorBoundaryFallback.tsx';
import {lazy} from 'react';
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const LazyNotFound = lazy(async () => import('../modules/NotFound.tsx'));

export const router = sentryCreateBrowserRouter([
  {path: '/', ErrorBoundary, component: <Home />},
  {
    path: '*',
    ErrorBoundary,
    element: (
      <ErrorBoundary fallback={ErrorBoundaryFallback} showDialog>
        <AppShell>
          <LazyNotFound />
        </AppShell>
      </ErrorBoundary>
    ),
  },
]);
