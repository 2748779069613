export const routes = {
  getRelativePath: (route: string, baseRoute: string) =>
    route.replace(`${baseRoute}/`, ''),
  replaceParams: (route: string, params: Record<string, string | number>) => {
    return Object.entries(params).reduce((updatedRoute, [key, value]) => {
      return updatedRoute.replace(new RegExp(`:${key}`, 'g'), value.toString());
    }, route);
  },
  addQueryParams: (route: string, params: Record<string, string>) => {
    const searchParams = new URLSearchParams(params);
    return `${route}?${searchParams.toString()}`;
  },
  auth: {redirect: '/login', login: '/login'},
  login: '/login',
  home: '/'
} as const;
