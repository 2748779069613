import {createContext, ReactNode, useContext, useMemo, useState} from 'react';

interface ThemeModeContextValue {
  mode: 'light' | 'dark';
  toggleMode: (mode?: 'light' | 'dark') => void;
}

export const ThemeModeContext = createContext<ThemeModeContextValue | null>(
  null,
);

export const useThemeModeContext = () => {
  const context = useContext(ThemeModeContext);
  if (context === null) {
    throw new Error(
      'useThemeModeContext must be used within a ThemeModeProvider',
    );
  }
  return context;
};

export function ThemeModeProvider({children}: {children: ReactNode}) {
  const [mode, setMode] = useState<ThemeModeContextValue['mode']>('light');

  return (
    <ThemeModeContext.Provider
      value={useMemo(
        () => ({
          mode,
          toggleMode: (targetMode) => {
            setMode(
              targetMode ?? ((prev) => (prev === 'light' ? 'dark' : 'light')),
            );
          },
        }),
        [mode],
      )}
    >
      {children}
    </ThemeModeContext.Provider>
  );
}
