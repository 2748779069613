import {Suspense, useEffect} from 'react';
import {RouterProvider} from 'react-router/dom';

import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';

import {AuthWrapper} from './modules/auth/AuthWrapper';
import {PageLoader} from './modules/common/components/loader/PageLoader';
import {router} from './router/router';
import {getTheme} from './theme/getTheme';
import {Toastify} from './Toastify';
import {
  ThemeModeContext,
  ThemeModeProvider,
} from './modules/common/contexts/ThemeModeContext.tsx';

function App() {
  useEffect(() => {
    const el = document.querySelector('#loading-vantage-message-container');
    if (el != null) {
      el.remove();
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeModeProvider>
        <ThemeModeContext.Consumer>
          {(theme) => (
            <ThemeProvider theme={createTheme(getTheme(theme?.mode))}>
              <CssBaseline enableColorScheme />
              <AuthWrapper>
                <Suspense fallback={<PageLoader />}>
                  <RouterProvider router={router} />
                </Suspense>
                <Toastify />
              </AuthWrapper>
            </ThemeProvider>
          )}
        </ThemeModeContext.Consumer>
      </ThemeModeProvider>
    </StyledEngineProvider>
  );
}

export default App;
