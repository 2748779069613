import {type ReactNode, Suspense} from 'react';
import {Outlet} from 'react-router';

import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

import {PageLoader} from '../loader/PageLoader';

export function AppShell({children}: {children?: ReactNode}) {
  return (
    <>
      <Container>
        {/*<TopBar />*/}
        <Suspense fallback={<PageLoader />}>
          <Stack overflow="auto" flex={1}>
            {children ?? <Outlet />}
          </Stack>
        </Suspense>
      </Container>
    </>
  );
}

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  flex: 1,
}));
