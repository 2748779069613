import {type ThemeOptions} from '@mui/material/styles';

import {themePaletteDark} from './themePaletteDark';
import {themePaletteLight} from './themePaletteLight';

export const getTheme = (mode?: 'dark' | 'light'): ThemeOptions => {
  return {
    spacing: 10,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            whiteSpace: 'nowrap',
            borderRadius: 100,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {borderRadius: 100},
        },
      },
    },
    typography: {
      fontFamily:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
      fontSize: 13,
    },
    shape: {
      borderRadius: 16,
    },
    palette: {
      contrastThreshold: 3.1,
      warning: {main: '#FF9000', contrastText: '#fff'},
      success: {main: '#00944F'},
      error: {main: '#E9291C'},
      info: {main: '#064C55'},
      common: {white: '#E7E7E7', black: '#121212'},
      mode,
      primary: {main: '#F26722'},
      secondary: {main: '#4d4d4d'},
      ...(mode === 'dark' ? themePaletteDark : themePaletteLight),
    },
  };
};
